<script setup lang="ts">
import { addSlash } from '~/src/helpers.ts';
import { addPronounInjectionKey } from '~/src/injectionKeys.ts';

const props = defineProps<{
    path: string;
}>();

const addPronoun = inject(addPronounInjectionKey, undefined);

const config = useConfig();

const link = computed(() => {
    return addSlash(`${config.pronouns.prefix || ''}/${props.path.replaceAll(/[^\w`&/|,:]+/g, encodeURIComponent)}`);
});
</script>

<template>
    <nuxt-link v-if="addPronoun === undefined" :to="link">
        <slot></slot>
    </nuxt-link>
    <template v-else>
        <span class="text-primary"><slot></slot></span>
        <button type="button" class="btn btn-sm p-0 text-success" @click="addPronoun(path)">
            <Icon v="plus-circle" hover />
        </button>
    </template>
</template>
