<script setup lang="ts">
import { buildPronounUsage } from '~/src/buildPronoun.ts';
import type { Pronoun } from '~/src/classes.ts';
import { loadPronounLibrary } from '~/src/data.ts';

const props = defineProps<{
    pronouns: Pronoun[] | string[];
}>();

const { $translator: translator } = useNuxtApp();
const config = useConfig();

const pronounLibrary = await loadPronounLibrary(config);

const glue = ` ${translator.translate('pronouns.or')} `;

const visiblePronouns = computed(() => {
    return props.pronouns
        .map((pronoun) => {
            if (typeof pronoun === 'string') {
                const usage = buildPronounUsage(pronounLibrary, pronoun, config, translator);
                if (usage) {
                    return {
                        path: pronoun,
                        short: usage.short.options.join(usage.short.glue),
                    };
                }
            } else if (!pronoun.hidden) {
                return {
                    path: pronoun.canonicalName,
                    short: pronoun.name(glue),
                    description: pronoun.description,
                    normative: pronoun.normative,
                    smallForm: pronoun.smallForm ? pronoun.morphemes[pronoun.smallForm] : undefined,
                };
            }
        })
        .filter((entry) => entry !== undefined);
});
</script>

<template>
    <ul>
        <li v-for="pronoun in visiblePronouns" :key="pronoun.path">
            <PronounsIndexLink :path="pronoun.path">
                <strong><Spelling :text="pronoun.short" /></strong><small v-if="pronoun.smallForm">/<Spelling :text="pronoun.smallForm" /></small>
                <template v-if="pronoun.description">
                    –
                    <small><Spelling :text="pronoun.description as string" /></small>
                </template>
            </PronounsIndexLink>
            <NormativeBadge v-if="pronoun.normative" />
        </li>
    </ul>
</template>
